@import "../variables";

.logo {
  // display: flex;
  // gap: 8px;
  // align-items: center;

  img {
    width: 150px;
  }

  // h4 {
  //   font-family: "Garant Pro Bold";
  //   font-size: 26px;
  //   line-height: 34px;
  //   letter-spacing: 0.02em;
  //   font-weight: 600;
  // }
}

.nav--bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 24px;
  padding-bottom: 24px;

  .mobile--menu {
    display: none;
  }

  .primary--nav {
    display: flex;
    align-items: center;

    ul {
      display: flex;
      list-style: none;
      gap: 40px;
      padding: 0;
      margin: 0;

      li {
        font-family: "BR Sonoma";
        color: $white;
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;
        display: flex;
        gap: 8px;
        align-items: center;
        cursor: pointer;

        &:hover {
          color: $gold;
        }
      }
    }
  }

  .secondary--nav {
    display: flex;
    gap: 10px;

    .wait--list--button {
      height: 44px;
    }
  }
}

@media screen and (min-width: 1280px) {
  .nav--bar {
    max-width: 1040px;
    margin: 0 auto;
  }
}

@media screen and (max-width: 1024px) {
  .nav--bar .primary--nav ul {
    gap: 24px;
  }
}

@media screen and (max-width: 767px) {
  .nav--bar {
    padding-top: 24px;
    padding-bottom: 24px;

    .primary--nav,
    .secondary--nav {
      display: none;
    }

    .mobile--menu {
      display: block;
      color: white;

      .menu--icon {
        width: 40px;
        height: 40px;

        &:active {
          transform: scale(0.9);
        }
      }

      .modal--container {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        height: 100vh;
        background: transparent;
        z-index: 99999;
        opacity: 1;
        transition: 0.5s ease-in-out;
      }

      .mobile--nav {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        padding-top: 20px;
        padding-bottom: 20px;
        background: $black;
        display: flex;
        flex-direction: column;
        gap: 25px;
        transition: 0.5s ease-in-out;

        .close--btn {
          width: 100%;
          display: flex;
          justify-content: flex-end;
        }

        .primary--nav {
          display: block;

          ul {
            display: flex;
            flex-direction: column;
            gap: 40px;
          }
        }

        .secondary--nav {
          display: flex;
          flex-direction: column;
          margin-top: 20px;
          gap: 15px;

          button {
            width: 100%;
            max-width: 400px;
            margin: 0 auto;
            justify-content: center;
          }
        }
      }

      .hide--modal {
        opacity: 0;
        visibility: hidden;

        .mobile--nav {
          top: -500px;
        }
      }
    }
  }
}

@media screen and (max-width: 540px) {
  .logo {
    img {
      width: 100px;
      // height: 90px;
    }

    // h4 {
    //   font-size: 26px;
    //   line-height: 34px;
    //   font-size: 18px;
    //   line-height: 20px;
    // }
  }
}
