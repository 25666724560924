@import "../variables";

.hero {
  border-bottom: $border;
  width: 100%;

  .container {
    display: flex;
    gap: 87px;
    padding-top: 90px;
    padding-bottom: 175px;
    align-items: center;
  }

  h1 {
    font-family: "Garant Pro Bold";
    font-size: 70px;
    line-height: 80px;
    display: flex;
    align-items: center;
    font-weight: 600;
  }

  h6 {
    width: 515px;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 36px;
    color: $grey;
  }

  .hero--text {
    display: flex;
    flex-direction: column;
    gap: 30px;
  }

  .hero--img img {
    width: 100%;
    height: 100%;
  }
}

@media (min-width: 1280px) {
  .hero .container {
    margin: 0 auto;
    max-width: 1040px;

    h1 {
      width: 564px;
    }
  }
}

@media screen and (max-width: 1190px) {
  .hero .container {
    padding-top: 90px;
    padding-bottom: 90px;

    .hero--img img {
      width: 90% !important;
    }

    h1 {
      font-size: 46px;
      line-height: 56px;
    }

    h6 {
      width: 396px;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 32px;
    }
  }
}

@media (min-width: 1024px) and (max-width: 1190px) {
  .hero .container {
    gap: 74px;

    h1 {
      font-size: 55px;
      line-height: 65px;
      width: 460px;
    }

    h6 {
      width: 450px;
      font-size: 16px;
      line-height: 32px;
    }
  }
}

@media (min-width: 768px) and (max-width: 912px) {
  .hero .container {
    gap: 0;
  }
}

@media screen and (max-width: 764px) {
  .hero .container {
    padding-top: 30px;
    padding-bottom: 40px;
    gap: 60px;
    flex-direction: column;
    align-items: flex-start;

    .hero--text {
      gap: 20px;
    }

    .hero--img {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    h1 {
      font-size: 38px;
      line-height: 50px;
    }

    h6 {
      font-size: 14px;
      line-height: 24px;
      width: 315px;
    }
  }
}
