@import "../variables";

.scan--box {
  display: flex;
  gap: 10px;
  align-items: center;
  padding: 0 15px;
  box-sizing: border-box;
  width: 190px;
  height: 74px;
  background: $white;
  border-radius: 8px;

  p {
    font-family: "BR Sonoma";
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    line-height: 14px;
    color: $deepBlue;
  }

  .img--container {
    position: relative;

    .box {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 20px;
      height: 20px;
      background: $white;
      border-radius: 3px;
    }
  }
}
