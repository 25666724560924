.FAQs {
  width: 100%;
  border-bottom: $border;

  .container {
    display: flex;
    flex-direction: column;
    padding-top: 60px;
    padding-bottom: 60px;
    gap: 60px;
  }

  .faq--title {
    display: flex;
    flex-direction: column;
    gap: 10px;

    h1 {
      font-family: "Garant Pro Bold";
      width: 100%;
      height: 80px;
      font-size: 28px;
      line-height: 40px;
      font-weight: 600;
    }

    p {
      font-family: "BR Sonoma";
      font-size: 12px;
      line-height: 24px;
      color: $grey;

      a {
        text-decoration: none;
        color: $grey;
      }
    }
  }

  .questions {
    display: flex;
    flex-direction: column;
    gap: 15px;

    .question {
      padding: 15px;
      background: $midBlack;
      border: $border;
      border-radius: 10px;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      gap: 5px;
      min-height: 60px;
      width: 100%;

      @media (min-width: 768px) {
        padding: 15px 20px;
      }

      @media (max-width: 374px) {
        padding: 10px;
      }

      .question--header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        cursor: pointer;
      }

      .question--title {
        font-size: 12px;
        line-height: 24px;
      }

      .img--container {
        width: 20px;
        height: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;

        img {
          width: 11.64px;
        }
      }

      .answer {
        font-size: 12px;
        line-height: 24px;
        color: $grey;
      }
    }
  }
}

@media (min-width: 541px) {
  .FAQs .container {
    padding-top: 64px;
    padding-bottom: 64px;
  }
}

@media (min-width: 1024px) {
  .FAQs .container {
    flex-direction: row;

    .faq--title {
      h1 {
        font-size: 40px;
        line-height: 50px;
        height: 100px;
      }
    }

    .questions .question {
      width: 510px;
    }
  }
}

@media (min-width: 1040px) {
  .FAQs .container {
    gap: 100px;

    .faq--title {
      h1 {
        width: 419px;
        height: 120px;
        font-size: 50px;
        line-height: 60px;
      }

      p {
        width: 399px;
        height: 48px;
        font-size: 14px;
        line-height: 24px;
      }
    }

    .questions .question {
      width: 510px;
    }
  }
}

@media screen and (min-width: 1280px) {
  .FAQs .container {
    padding-top: 120px;
    padding-bottom: 120px;
    max-width: 1040px;
    margin: 0 auto;

    .faq--title {
      gap: 25px;
    }
  }
}
