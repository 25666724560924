@import "../variables";

.primary--button {
  display: flex;
  padding: 10px 18px;
  gap: 10px;
  width: fit-content;
  height: 40px;
  background: $white;
  border-radius: 5px;
  border: 1px solid transparent;
  box-sizing: border-box;

  p {
    font-weight: 600;
    color: $black;
    font-style: normal;
    font-size: 14px;
    line-height: 20px;
    border: 0;
  }
}

.secondary--button {
  display: flex;
  padding: 10px 18px;
  width: fit-content;
  gap: 10px;
  height: 40px;
  background: $black;
  border-radius: 5px;
  border: 1px solid $grey;
  box-sizing: border-box;

  p {
    font-weight: 600;
    color: $white;
    font-style: normal;
    font-size: 14px;
    line-height: 20px;
    border: 0;
  }
}

.wait--list--button {
  padding: 15px 20px;
  background: #2b2623;
  border: 0.5px solid rgba(255, 206, 0, 0.2);
  border-radius: 5px;
  width: fit-content;
  display: flex;
  gap: 13.3px;
  align-items: center;
  height: 51px;
  box-sizing: border-box;

  p {
    font-family: "BR Sonoma";
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    display: flex;
    align-items: center;
    color: $gold;
  }
}


.loader {
  max-height: 50px;
  position: relative;
  margin-top: -28px;
}


button {
  cursor: pointer;
}

@media screen and (max-width: 540px) {
  button {
    width: 100%;
  }
}
