@import "./variables";
@import "./Navbar/navbar";
@import "./Buttons/buttons";
@import "./Hero/hero";
@import "./ScanBox/scanbox";
@import "./Product/product";
@import "./Features/features";
@import "./FAQs/faqs";
@import "./CTA/cta";
@import "./Footer/footer";
@import "./Modal/modal";

@import "./test";

@font-face {
  font-family: "Garant Pro Bold";
  src: local("Garant_Pro_Bold"),
    url("../assets/fonts/BrassFonts-BF-Garant-Pro-Semi-Bold.otf")
      format("opentype");
}

@font-face {
  font-family: "Garant Pro Medium";
  src: local("Garant_Pro_Medium"),
    url("../assets/fonts/BrassFonts-BF-Garant-Pro-Medium.otf")
      format("opentype");
}

@font-face {
  font-family: "Garant Pro";
  src: local("Garant_Pro"),
    url("../assets/fonts/BrassFonts-BF-Garant-Pro-Regular.otf")
      format("opentype");
}

@font-face {
  font-family: "BR Sonoma";
  src: local("BRSonoma"),
    url("../assets/fonts/BRSonoma-Regular.otf") format("opentype");
}

html {
  box-sizing: border-box;
}

body {
  padding: 0;
  margin: 0;
  font-family: "Garant Pro", sans-serif;
  background: $black;

  .container {
    @media (max-width: 540px) {
      padding-left: 20px;
      padding-right: 20px;
    }
  }

  p,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0;
    color: $white;
  }

  label {
    cursor: pointer;
  }
  img {
    cursor: pointer;
  }
}
