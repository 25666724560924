@import "../variables";

.footer {
  padding-top: 60px;
  padding-bottom: 60px;
  display: flex;
  flex-direction: column;

  .top--footer {
    display: flex;
    flex-direction: column;
    gap: 60px;
    padding-bottom: 60px;
    border-bottom: $border;
  }

  .footer--sub {
    display: flex;
    flex-direction: column;
    gap: 25px;

    h4 {
      font-family: "Garant Pro Bold";
      font-size: 26px;
      line-height: 34px;
      font-weight: 600;
    }

    .social--container {
      display: flex;
      gap: 5px;
      width: 100%;

      a {
        text-decoration: none;
      }
    }

    .sub--container {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 16px 20px;
      gap: 10px;
      background: $midBlack;
      border: $border;
      border-radius: 10px;
      justify-content: center;

      p {
        font-size: 12px;
        line-height: 18px;
      }

      .form--container {
        width: 100%;
        border: $border;
        border-radius: 5px;
        display: flex;
        align-items: center;
        padding: 2px;
        margin: 0 auto;

        input {
          width: 100%;
          background: transparent;
          border: 0;
          font-size: 16px;
          line-height: 18px;
          padding: 0 15px;
          color: $grey;
          border: 0;

          &:focus {
            outline: none;
          }
        }

        button {
          height: 30px;
          width: 74px;
          display: flex;
          align-items: center;
          justify-content: center;

          p {
            font-size: 10px;
            line-height: 14px;
            font-weight: 600;
          }
        }
      }
    }
  }

  .footer--links {
    display: flex;
    justify-content: space-between;

    .link--row {
      display: flex;
      flex-direction: column;
      gap: 20px;

      .title {
        font-family: "BR Sonoma";
        font-style: normal;
        font-weight: 500;
        font-size: 10px;
        line-height: 12px;
        color: $grey;
      }

      .list--item {
        font-family: "BR Sonoma";
        font-style: normal;
        font-size: 10px;
        line-height: 12px;
        text-transform: capitalize;
        cursor: pointer;

        &:hover {
          color: $gold;
        }
      }
    }
  }
}

.lower--links {
  padding-top: 25px;
  display: flex;
  flex-direction: column;
  gap: 20px;

  .rights {
    font-size: 10px;
    line-height: 12px;
    font-weight: 500;
  }

  .links--list {
    display: flex;
    flex-direction: column;
    gap: 20px;

    .links--list--item {
      font-family: "BR Sonoma";
      font-style: normal;
      font-size: 10px;
      line-height: 12px;
      cursor: pointer;

      &:hover {
        color: $gold;
      }
    }
  }
}

@media (min-width: 768px) and (max-width: 912px) {
  .footer {
    padding-top: 60px;
    padding-bottom: 60px;

    .top--footer .footer--sub {
      min-width: 422px;
      max-width: 422px;
    }

    .lower--links {
      flex-direction: row-reverse;
      justify-content: space-between;

      .links--list {
        flex-direction: row;
      }
    }
  }
}

@media (min-width: 1024px) {
  .footer {
    padding-top: 60px;
    padding-bottom: 60px;

    .top--footer {
      width: 100%;
      flex-direction: row;
      gap: 108px;
      justify-content: space-between;

      .footer--sub {
        min-width: 422px;
        max-width: 422px;

        .sub--container input {
          font-size: 12px;
          line-height: 18px;
        }
      }

      .footer--links {
        width: 100%;
        max-width: 602px;
      }
    }

    .lower--links {
      flex-direction: row-reverse;
      justify-content: space-between;

      .links--list {
        flex-direction: row;
      }
    }
  }
}

@media screen and (min-width: 1280px) {
  .footer {
    padding-top: 60px;
    padding-bottom: 60px;
    margin: 0 auto;
    max-width: 1040px;
  }
}
