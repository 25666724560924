@import "../variables";

.call--to--action {
  border-bottom: $border;
  width: 100%;

  .container {
    padding-top: 80px;
    padding-bottom: 80px;
  }

  .cta--container {
    background: $midBlack;
    display: flex;
    padding: 60px 55px;
    gap: 75px;
    border: $border;
    box-shadow: $boxShadow;
    border-radius: 10px;
    align-items: center;
    width: fit-content;
    margin: 0 auto;

    .cta--text {
      display: flex;
      flex-direction: column;
      gap: 15px;

      h1 {
        font-family: "Garant Pro Bold";
        font-size: 40px;
        line-height: 50px;
        font-weight: 600;
      }

      p {
        font-size: 14px;
        line-height: 24px;
        color: $grey;
        max-width: 449px;
      }
    }

    .scan--box {
      min-width: 245px;
      max-width: 245px;
      min-height: 130px;
      max-height: 130px;

      img {
        width: 118px;
        height: 118px;
      }
    }
  }
}

@media (min-width: 1280px) {
  .call--to--action .container {
    width: 864px;
    margin: 0 auto;

    .cta--text h1 {
      width: 289px;
    }
  }
}

@media screen and (max-width: 912px) {
  .call--to--action .container .cta--container {
    flex-direction: column;
    padding: 40px 35px;
    gap: 24px;
    align-items: flex-start;

    .cta--text h1 {
      font-size: 24px;
      line-height: 30px;
    }

    .cta--text p {
      font-size: 12px;
      line-height: 24px;
      max-width: 372px;
    }
  }
}

@media (min-width: 768px) and (max-width: 912px) {
  .call--to--action .container .cta--container {
    flex-direction: row;
  }
}
