.product {
  border-bottom: $border;
  width: 100%;

  .container {
    padding-top: 60px;
    padding-bottom: 60px;
    display: flex;
    flex-direction: column;
    gap: 40px;
  }

  .product--box {
    padding: 40px 30px;
    border: $border;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    gap: 40px;

    .product--box--text {
      display: flex;
      flex-direction: column;
      gap: 15px;

      h4 {
        font-family: "Garant Pro Bold";
        font-size: 24px;
        line-height: 30px;
        font-weight: 600;
      }

      .box--info {
        font-size: 12px;
        line-height: 24px;
        color: $grey;
        width: 100%;
      }
    }
  }

  .coming--soon--box {
    display: flex;
    gap: 20px;

    .coming--soon {
      padding-bottom: 15px;
      max-width: 203px;
      height: 273px;
      background: $midBlack;
      border: $border;
      box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.1);
      border-radius: 10px;
      display: flex;
      flex-direction: column;
      gap: 10px;
      padding-bottom: 15px;

      @media (max-width: 375px) {
        max-width: 190px !important;
        height: 283px;
      }

      .img-container {
        padding: 10px 22px;
        margin: 0 auto;

        img {
          width: 99.19px;
          height: 98px;
        }
      }

      .coming--text--box {
        padding: 0 15px;
        display: flex;
        flex-direction: column;
        gap: 10px;

        h4 {
          font-family: "Garant Pro Bold";
          font-size: 12px;
          line-height: 16px;
          width: 100%;
        }

        p {
          font-size: 10px;
          line-height: 16px;
          color: $grey;
        }

        h5 {
          font-family: "BR Sonoma";
          font-size: 10px;
          line-height: 20px;
          color: $gold;
          text-transform: capitalize;
        }
      }
    }
  }

  .mobile--box {
    display: flex;
  }

  .web--box {
    display: none;
  }
}

@media (min-width: 660px) and (max-width: 912px) {
  .product .container {
    padding-top: 78px;
    padding-bottom: 78px;

    .product--box {
      padding: 25px 50px;

      .product--box--text {
        gap: 25px;

        .box--info {
          width: 80%;
        }
      }

      img {
        width: 80%;
        margin: 0 auto;
      }
    }

    .coming--soon--box .coming--soon {
      min-width: 243px;
      max-width: 243px;
      height: 263px;
      padding-bottom: 0px;

      img {
        max-height: 118px;
        width: 183px;
        margin: 0 auto;
      }

      .coming--text--box {
        padding: 0 20px;
      }
    }
  }
}

@media (min-width: 992px) {
  .product .container {
    padding-top: 78px;
    padding-bottom: 78px;

    .product--box {
      flex-direction: row;
      align-items: center;
      padding: 25px 50px;

      .product--box--text {
        gap: 25px;
      }
    }

    .coming--soon--box .coming--soon {
      min-width: 243px;
      max-width: 243px;
      height: 263px;
      padding-bottom: 0px;

      img {
        max-height: 118px;
        width: 183px;
        margin: 0 auto;
      }

      .coming--text--box {
        padding: 0 20px;
      }
    }
  }
}

@media (min-width: 992px) and (max-width: 1040px) {
  .product .container .product--box .product--box--text h4 {
    width: 348px;
    height: 100px;
    font-size: 40px;
    line-height: 50px;
  }
}

@media (min-width: 1280px) {
  .product .container {
    padding-top: 78px;
    padding-bottom: 78px;
    margin: 0 auto;
    max-width: 1040px;

    .product--box {
      gap: 62px;

      .product--box--text {
        h4 {
          width: 348px;
          height: 100px;
          font-size: 40px;
          line-height: 50px;
        }

        .box--info {
          width: 418px;
          height: 120px;
          font-size: 14px;
          line-height: 24px;
        }
      }
    }
  }

  .coming--soon--box {
    justify-content: space-between;

    .coming--soon .coming--text--box {
      h4 {
        font-size: 16px;
        line-height: 16px;
      }
    }
  }

  .product .web--box {
    display: flex;
  }
}
