.features {
  width: 100%;
  border-bottom: $border;

  .container {
    padding-top: 60px;
    padding-bottom: 60px;
    border-bottom: $border;
    display: flex;
    flex-direction: column;
    gap: 50px;
  }

  h1 {
    width: 100%;
    height: 80px;
    font-family: "Garant Pro Bold";
    font-size: 28px;
    line-height: 40px;
    font-weight: 600;
  }

  .features--list {
    display: flex;

    .list {
      display: flex;
      flex-direction: column;
      gap: 40px;

      .feature {
        display: flex;
        gap: 21px;

        .box {
          min-width: 14px;
          height: 14px;
          background: #ffffff;
          border-radius: 2px;
        }

        .feature--details {
          display: flex;
          flex-direction: column;
          gap: 10px;

          h4 {
            font-family: "Garant Pro Bold";
            font-size: 14px;
            line-height: 20px;
          }

          p {
            font-size: 12px;
            line-height: 24px;
            color: $grey;
            width: 100%;
          }
        }
      }
    }

    img {
      display: none;
    }
  }
}

@media (min-width: 541px) {
  .features .container {
    padding-top: 78px;
    padding-bottom: 78px;

    .features--list .list .feature .feature--details p {
      width: 550px;
    }
  }
}

@media (min-width: 1024px) {
  .features .container {
    gap: 80px;

    h1 {
      font-size: 50px;
      line-height: 60px;
      width: 488px;
      height: 120px;
    }
    .features--list {
      justify-content: space-between;

      img {
        display: block;
      }

      .list .feature .feature--details p {
        width: 328px;
      }
    }
  }
}

@media (min-width: 1024px) and (max-width: 1040px) {
  .features .container .features--list img {
    display: block;
    width: 50%;
  }
}

@media screen and (min-width: 1280px) {
  .features .container {
    padding-top: 111px;
    padding-bottom: 111px;
    margin: 0 auto;
    max-width: 1040px;

    .features--list {
      justify-content: flex-start;
      gap: 150px;
    }
  }
}
