@import "../variables";

.wait--list--modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 100vh;
  background: transparent;
  z-index: 99999;
  opacity: 1;
  transition: 0.5s ease-in-out;

  .modal {
    top: 0;
    left: 0;
    right: 0;
    height: 100%;
    display: flex;
    flex-direction: column;
    padding: 25px 20px;
    background: radial-gradient(
      39.44% 39.44% at 50% 0%,
      #444f68 9.9%,
      #21242d 57.29%,
      #161616 100%
    );
    box-shadow: 0px 30px 60px rgba(0, 0, 0, 0.15);

    .header {
      .img--container {
        width: 14px;
        height: 14px;
        float: right;
        cursor: pointer;

        @media (min-width: 768px) {
          width: 40px;
          height: 40px;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 40px;
          border: .5px solid rgba(255, 255, 255, 0.2);;
        }

        img: {
          width: 100%;
        }
      }

      .logo {
        margin-top: 10px;
      }
    }

    .modal--content {
      display: flex;
      flex-direction: column;
      gap: 40px;

      .text--container {
        display: flex;
        flex-direction: column;
        gap: 10px;
        align-items: center;
        margin-top: 80px;

        h4 {
          font-family: "Garant Pro Bold";
          font-style: normal;
          font-weight: 800;
          font-size: 24px;
          line-height: 30px;
        }

        .wait--list--info {
          font-weight: 500;
          font-size: 14px;
          line-height: 26px;
          text-align: center;
          color: $grey;
          width: 314px;
          margin: 0 auto;
        }
      }

      .form--control {
        display: flex;
        flex-direction: column;
        gap: 20px;

        button {
          width: 100%;
          justify-content: center;
        }

        .error--text {
          color: rgb(244, 62, 62);
          font-size: 14px;
          line-height: 20px;
          padding: 8px 4px 0;
        }

        .field--wrapper {
          position: relative;
          width: 100%;

          .icon {
            position: absolute;
            top: 17px;
            left: 15px;
            color: $grey;
          }

          .input--field {
            width: 100%;
            height: 50px;
            border-radius: 5px;
            border: 0;
            padding: 0 16px 0 40px;
            color: $grey;
            outline: none;
            font-size: 16px;
            background: transparent;
            border: $border;
            box-sizing: border-box;

            &:focus {
              background: #26262b;
              border: 1px solid transparent;
            }

            &::-webkit-input-placeholder {
              font-size: 14px;
              line-height: 20px;
              display: flex;
              align-items: center;
              color: $grey;
            }
          }
        }
      }
    }

    .lower--links {
      display: none;
    }
  }

  .success--submission {
    display: flex;
    gap: 18.17px;
    background: #26262b;
    border-radius: 5px;
    padding: 10px 20px;

    .error-message {
      color: #eb5757;
    }

    .error--icon {
      color: #EB5757;
      width: 20px;
      height: 20px;
    }

    .img--container {
      width: 20px;
      height: 20px;
      border: 0.5px solid #91c4ae;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 20px;
    }

    p {
      font-size: 14px;
      line-height: 24px;
      color: $grey;
      width: 238px;

      span {
        color: white;
      }
    }
  }

  .wait--list--social {
    display: flex;
    justify-content: space-around;
    width: 100%;
    margin: 0 auto;
    border-top: $border;
    padding: 40px 0;

    .social {
      display: flex;
      gap: 14px;
      align-items: center;

      .icon {
        color: rgba(255, 255, 255, 0.2);
        width: 20px;
        height: 20px;
      }

      .social--detail {
        display: flex;
        flex-direction: column;
        gap: 4px;

        p {
          font-size: 12px;
          line-height: 14px;
          color: $grey;
        }

        h6 {
          font-size: 12px;
          line-height: 14px;
          color: $white;

          a {
            color: $white;
            text-decoration: none;
          }
        }
      }
    }
  }
}

@media (min-width: 768px) {
  .wait--list--modal .modal {
    top: 0;
    left: 0;
    right: 0;
    padding: 50px;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: fit-content;
    border-radius: 20px;
    box-sizing: border-box;
    box-shadow: 0px 30px 60px rgba(0, 0, 0, 0.15);
    border-radius: 20px;

    .modal--content .text--container {
      h4 {
        font-size: 42px;
        line-height: 52px;
      }

      .wait--list--info {
        font-size: 18px;
        line-height: 30px;
        min-width: 403px;
      }
    }

    .modal--content .form--control {
      .field--wrapper {
        width: 334px;
        margin: 0 auto;
      }
      button {
        width: 334px;
        margin: 0 auto;
      }
    }

    .success--submission {
      max-width: 335px;
      margin: 0 auto;
    }

    .wait--list--social {
      width: 334px;
    }
  }
}

@media (min-width: 1280px) {
  .wait--list--modal .modal {
    width: 1040px;
    max-width: 1040px;
  }
}
