$xxSmall: 10px;
$xSmall: 12px;
$small: 14px;
$medium: 16px;
$large: 20px;
$xLarge: 24px;
$xxLarge: 32px;

//  COLORS
$black: #17181d;
$gold: #ffce00;
$white: #ffffff;
$border: 1px solid #242528;
$grey: rgba(255, 255, 255, 0.6);
$deepBlue: #1b2034;
$midBlack: #1a1b1f;
$boxShadow: 0px 5px 10px rgba(0, 0, 0, 0.1);
